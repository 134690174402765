<template>
  <!-- app用户管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>

                <a-col :md="5" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option :value="1">正常</a-select-option>
                      <a-select-option :value="3">停用</a-select-option>
                      <a-select-option :value="2">已注销</a-select-option>
                      <a-select-option :value="4">违规</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="4" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                </a-col>
                <!-- 新增按钮 -->
                <!--                <a-col :md="24" :sm="24" v-if="hasPerm('customer:edit')">-->
                <!--                  <a-button-->
                <!--                    type="primary"-->
                <!--                    style="border-radius: 3px; margin-bottom: 20px"-->
                <!--                    @click="openBatchEditForm(1)"-->
                <!--                  >-->
                <!--                    批量编辑粉丝基数</a-button-->
                <!--                  >-->
                <!--                  <a-button-->
                <!--                    type="primary"-->
                <!--                    style="border-radius: 3px; margin-bottom: 20px; margin-left: 20px"-->
                <!--                    @click="openBatchEditForm(2)"-->
                <!--                    >批量编辑关注基数</a-button-->
                <!--                  >-->
                <!--                </a-col>-->
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: true }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
            :row-selection="rowSelection"
          >
            <!-- 头像插槽 -->
            <span slot="avatar" slot-scope="text">
              <img :src="text" alt="" />
            </span>
            <!-- 账号状态插槽 -->
            <span slot="accountStatus" slot-scope="text">
              <a-tag :color="text == 1 ? 'green' : text == 2 ? 'red' : ''">
                {{ text == 1 ? '正常' : text == 2 ? '违规' : '' }}
              </a-tag>
            </span>
            <!--            官方平台账号 -->
            <span slot="isPlatform" slot-scope="text">
              <a-tag :color="text == 1 ? 'red' : 'green'">
                {{ text == 1 ? '否' : '是' }}
              </a-tag>
            </span>
            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <span v-if="record.delFlag == 2">
                <a-tag color="red">已注销</a-tag>
              </span>
              <span v-else>
                <a-switch
                  checked-children="正常"
                  un-checked-children="停用"
                  v-model="record.checked"
                  @change="changeSwitch"
                />
              </span>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <!--              <a-dropdown v-if="hasPerm('customer:edit')">-->
              <!--                <a class="ant-dropdown-link">-->
              <!--                  更多-->
              <!--                  <a-icon type="down" />-->
              <!--                </a>-->
              <!--                <a-menu slot="overlay">-->
              <!--                  <a-menu-item>-->
              <!--                    <a @click="$refs.fansForm.show(record)">编辑粉丝基数</a>-->
              <!--                  </a-menu-item>-->
              <!--                  <a-menu-item>-->
              <!--                    <a @click="$refs.followForm.show(record)">编辑关注基数</a>-->
              <!--                  </a-menu-item>-->
              <!--                </a-menu>-->
              <!--              </a-dropdown>-->
              <a-button
                type="link"
                @click="setOfficialAccount(record)"
                :style="[+record.isPlatform === 2 ? { color: '#f5222d' } : {}]"
              >
                {{ +record.isPlatform === 1 ? '设置官方账号' : '取消官方账号' }}
              </a-button>
              <a-button type="link" @click="$refs.addBalance.show(record)"> 添加金额 </a-button>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      >
      </a-pagination>
    </div>
    <!-- 引入子组件 -->
    <fans-form ref="fansForm" @ok="getList"></fans-form>
    <follow-form ref="followForm" @ok="getList"></follow-form>
    <add-balance ref="addBalance" @ok="getList"></add-balance>
  </div>
</template>

<script>
import { customerPageList, customerDelete, setUpOrCancelAccount } from '@/api/modular/mallLiving/userAdmin'

import fansForm from './fansForm.vue'
import followForm from './followForm.vue'
import addBalance from './addBalance.vue'
const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '昵称',
    align: 'left',
    dataIndex: 'nickname',
    width: '180px',
  },
  {
    title: 'ID号',
    dataIndex: 'idNo',
    align: 'center',
    width: '120px',
  },

  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'left',
    width: '120px',
  },
  {
    title: '账号状态',
    dataIndex: 'accountStatus',
    key: 'accountStatus',
    align: 'left',
    width: '110px',
    scopedSlots: {
      customRender: 'accountStatus',
    },
  },
  {
    title: '官方平台账号',
    dataIndex: 'isPlatform',
    key: 'isPlatform',
    align: 'left',
    width: '110px',
    scopedSlots: {
      customRender: 'isPlatform',
    },
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    align: 'left',
    width: '100px',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '余额',
    dataIndex: 'balance',
    align: 'left',
    width: '120px',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    align: 'left',
    width: '100px',
    scopedSlots: {
      customRender: 'delFlag',
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '修改时间',
    dataIndex: 'updateTime',
    align: 'left',
    width: '180px',
  },
  {
    title: '注销时间',
    width: '180px',
    dataIndex: 'cancellationTime',
    align: 'left',
  },
  {
    title: '操作',
    align: 'left',
    width: '300px',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      recordItem: null,
      total: 0,
      loading: false,
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  components: {
    fansForm,
    followForm,
    addBalance,
  },
  mounted() {
    this.initTableData()
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectedRows.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  methods: {
    //设置官方账号
    setOfficialAccount(record) {
      console.log('record', record)
      let content = +record.isPlatform === 1 ? '确定将当前用户账号设置为官方账号？' : '确定取消当前用户的官方账号？'
      let msgContent = +record.isPlatform === 1 ? '设置官方账号成功' : '取消官方账号成功'
      let errorContent = +record.isPlatform === 1 ? '设置官方账号失败' : '取消官方账号失败'
      this.$confirm({
        title: '提示',
        content,
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          console.log('OK')
          setUpOrCancelAccount({ id: record.id, isPlatform: +record.isPlatform === 1 ? 2 : 1 })
            .then((res) => {
              if (res.success) {
                this.$message.success(msgContent)
                this.getList()
              }
            })
            .catch((error) => {
              this.$message.error(error.message || errorContent)
            })
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    openBatchEditForm(type) {
      if (this.selectRowId.length > 0) {
        if (type == 1) {
          this.$refs.fansForm.batchShow(this.selectRowId)
        } else {
          this.$refs.followForm.batchShow(this.selectRowId)
        }
      } else {
        this.$message.error('请选择要操作的数据')
        return
      }
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectRowId = []
      this.selectedRowKeys = []
      customerPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    // sysItemDelete(record){
    //   this.recordItem = record
    //   this.disableCommodityCate(2) //2:删除
    // },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await customerDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '删除' : '停用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.nickname + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
