<template>
  <a-modal
    title="添加金额"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    :destroyOnClose="true"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="金额" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input-number
                placeholder="请输入"
                style="width: 240px"
                v-decorator="['price', { rules: [{ required: true, message: '请输入！' }] }]"
              />
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
    </a-spin>
  </a-modal>
</template>

<script>
import { addBalance } from '@/api/modular/mallLiving/userAdmin'

export default {
  data() {
    return {
      visible: false, //modal框显示状态
      confirmLoading: false,
      details: {},
      form: this.$form.createForm(this),
      showBigImg: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      editId: '',
      fromType: '',
      editIds: '',
    }
  },
  methods: {
    //初始化方法
    show(record) {
      this.visible = true
      this.editId = record.id
    },

    handleSubmit() {
      this.confirmLoading = true
      this.form.validateFields((errors, values) => {
        if (!errors) {
          addBalance({ ...values, customerId: this.editId }).then((res) => {
            if (res.success) {
              this.$message.success('添加金额成功！')
              setTimeout(() => {
                this.$emit('ok', values)
              }, 1000)
              this.confirmLoading = false
              this.handleCancel()
            } else {
              setTimeout(() => {
                this.confirmLoading = false
              }, 600)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false //关闭对话框
      this.confirmLoading = false
      this.form.resetFields() //重置表单
    },
  },
}
</script>
